var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('div',{staticClass:"roll-item",style:({
  backgroundImage: `url(${require(`@/assets/images/Roll/mb-roll-boxbg${_vm.item.type ===0 ? 2: 1}.png`)})`,
  backgroundSize: `100% 100%`,
}),on:{"click":function($event){return _vm.$emit('itemClick', _vm.item.id)}}},[( _vm.item.status == 1 || _vm.item.status == 3)?_c('div',{staticClass:"mask"},[(_vm.item.status == '1')?_c('div',{staticClass:"end-box"},[_vm._m(0),_c('div',{staticClass:"time"},[_vm._v("开奖时间："+_vm._s(_vm.item.end_time))])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"headimg"},[_c('img',{attrs:{"src":_vm.item.user ? _vm.item.user.avatar : '',"alt":""}})]),_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"textbox",style:({
  backgroundImage: `url(${require(`@/assets/images/Roll/mb-roll-text${_vm.item.type ===0 ? 2: 1}.png`)})`,
  backgroundSize: `100% 100%`,
})},[_c('span',{style:({color:`${_vm.item.type === 0 ? '#967CE3': '#FFA439' }`})},[_vm._v(_vm._s(_vm.item.type === 0 ? "官方" : "主播"))])])]),_c('div',{staticClass:"content"},_vm._l((_vm.item.awards ? _vm.item.awards.slice(0, 3) : []),function(i,j){return _c('div',{key:j,staticClass:"content-item",style:({
      background: `url(${require(`@/assets/images/Roll/mb-roll-good-bg${_vm.item.type ===0 ? 2: 1}.png`)}) no-repeat `,
      backgroundSize: `100% 100%`,
    })},[_c('img',{attrs:{"src":i.box_record?.cover,"width":"88%","alt":""}})])}),0),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"timebox"},[(_vm.item.type==1)?_c('img',{attrs:{"src":require("../../../assets/images/Roll/mb-roll-time-icon1.png"),"alt":""}}):_vm._e(),(_vm.item.type==0)?_c('img',{attrs:{"src":require("../../../assets/images/Roll/mb-roll-time-icon2.png"),"alt":""}}):_vm._e(),_c('span',{style:({color:`${_vm.item.type === 0 ? '#967CE3': '#FFA439' }`})},[_vm._v(_vm._s(_vm.item.end_time))])]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.item.awards_count)?_c('div',{staticClass:"all-goods"},[_c('img',{attrs:{"src":require("../../../assets/images/Roll/mb-roll-icon2.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.item.awards_count)+" ")]):_vm._e(),_c('div',{staticClass:"all-user"},[_c('img',{attrs:{"src":require("../../../assets/images/Roll/mb-roll-people-icon.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.item.join_number)+" ")]),_c('div',{staticClass:"price"})])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"end-text"},[_c('img',{attrs:{"src":require("../../../assets/images/Roll/pc-roll-end-tip.png"),"alt":""}}),_vm._v(" 已结束 ")])
}]

export { render, staticRenderFns }