<template>
  <div class="roll-item" v-if="item" :style="{
    backgroundImage: `url(${require(`@/assets/images/Roll/mb-roll-boxbg${item.type ===0 ? 2: 1}.png`)})`,
    backgroundSize: `100% 100%`,
  }" @click="$emit('itemClick', item.id)">
    <div class="mask" v-if=" item.status == 1 || item.status == 3">
      <div class="end-box" v-if="item.status == '1'">
        <div class="end-text">
          <img src="../../../assets/images/Roll/pc-roll-end-tip.png" alt="" />
          已结束
        </div>
        <div class="time">开奖时间：{{ item.end_time }}</div>
      </div>
    </div>
    <div class="title-box">
      <div class="title">
        <div class="headimg">
          <img :src="item.user ? item.user.avatar : ''" alt="">
        </div>
        {{ item.name }}
      </div>
      <div class="textbox" :style="{
    backgroundImage: `url(${require(`@/assets/images/Roll/mb-roll-text${item.type ===0 ? 2: 1}.png`)})`,
    backgroundSize: `100% 100%`,
  }"> <span :style="{color:`${item.type === 0 ? '#967CE3': '#FFA439' }`}">{{ item.type === 0 ? "官方" : "主播" }}</span></div>

    </div>
    <div class="content">
      <div class="content-item" v-for="(i, j) in item.awards ? item.awards.slice(0, 3) : []" :key="j" :style="{
        background: `url(${require(`@/assets/images/Roll/mb-roll-good-bg${item.type ===0 ? 2: 1}.png`)}) no-repeat `,
        backgroundSize: `100% 100%`,
      }">
        <img :src="i.box_record?.cover" width="88%" alt="" />
      </div>
    </div>
    <div class="info-box">
      <div class="timebox">
        <img v-if="item.type==1" src="../../../assets/images/Roll/mb-roll-time-icon1.png" alt="">
        <img v-if="item.type==0" src="../../../assets/images/Roll/mb-roll-time-icon2.png" alt="">
        <span :style="{color:`${item.type === 0 ? '#967CE3': '#FFA439' }`}">{{ item.end_time }}</span>
      </div>
      <div style="display:flex;align-items: center;">
        <div v-if="item.awards_count" class="all-goods">
          <img src="../../../assets/images/Roll/mb-roll-icon2.png" alt="" />
          {{ item.awards_count}}
        </div>
        <div class="all-user">
          <img src="../../../assets/images/Roll/mb-roll-people-icon.png" alt="" />
          {{ item.join_number }}
        </div>
        <div class="price">
          <!-- <Cprice :price="item.award_bean" :size=0.1 /> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Cprice from '../../../components/common/Cprice.vue';


export default {
  props: {
    item: {
      type: Object,
      default: () => { },
    },
    type: {
      type: String,
      default: () => "2",
    },
  },
  components: { Cprice }
};

</script>
<style lang="scss" scoped>
.roll-item {
  position: relative;
  width: 3.35rem;
  height: 1.26rem;
  box-sizing: border-box;
  margin-top: 0.12rem;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .add-box {
      .user-name {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.31rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;

        img {
          width: 0.72rem;
          height: 0.72rem;
          background: #ffffff;
          border-radius: 50%;
          margin-right: 0.08rem;
        }
      }

   
      .text {
        font-size: 0.25rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .end-box {
      .end-text {
        display: flex;
        align-items: center;
        font-size: 0.12rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        justify-content: center;

        img {
          width: 0.3rem;
          height: 0.3rem;
          margin-right: 0.08rem;
        }
      }

      .time {
        margin-top: 0.05rem;
        font-size: 0.12rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .title-box {
    display: flex;
    font-size: 0.12rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    height: 0.3rem;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.2rem;
    .title {
      padding-left: 0.14rem;
      display: flex;
      align-items: center;
      .headimg {
        width: 0.18rem;
        height: 0.18rem;
        border-radius: 50%;
        margin-right: 0.1rem;
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
    }
    .textbox {
      width: 0.5rem;
      height: 0.2rem;
      line-height: 0.16rem;
      font-style: italic;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.12rem;
      margin-top: 0.1rem;
    }
  }

  .content {
    width: 2.8rem;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    height: 0.5rem;
    margin: 0.07rem auto;
    .content-item {
      width: 2.08rem;
      height: 0.48rem;
      margin: 0 0.03rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        width: 0.55rem;
      }
    }
  }

  .info-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 0.3rem;
    justify-content: space-between;
    font-size: 0.1rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    box-sizing: border-box;
    padding: 0 0.2rem;
    .timebox {
      display: flex;
      align-items: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.1rem;
      color: #9380cb;
      img {
        width: 0.1rem;
        margin-right: 0.1rem;
      }
    }

    .all-goods {
      height: 100%;
      color: #0099ff;
      display: flex;
      align-items: center;
      img {
        margin-right: 0.08rem;
        width: 0.18rem;
      }
    }

    .all-user {
      height: 100%;
      margin: 0 0.2rem;
      color: #cc66ff;
      display: flex;
      align-items: center;
      img {
        margin-right: 0.08rem;
        width: 0.12rem;
      }
    }

    .price {
      height: 100%;
      color: #ff9000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
