<template>
  <div class="PageNum">
    <el-pagination
      :background="background"
      :layout="layout"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="page_size"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: Boolean,
      default: () => true,
    },
    layout: {
      type: String,
      default: () => "total, prev, pager, next",
    },
    page_size: {
      type: Number | String,
      default: () => 10,
    },
    total: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.PageNum {
  display: flex;
  justify-content: center;
}
</style>
