<template>
  <div id="Roll">
    <div class="Roll_box">
      <div class="tabbox">
        <div
          class="tabitem"
          :class="tabindex === 0 ? 'tabacbg' : ''"
          @click="tabChange(0)">
          官方ROLL房
        </div>
        <div
          class="tabitem"
          :class="tabindex === 1 ? 'tabacbg' : ''"
          @click="tabChange(1)">
          主播ROLL房
        </div>
        <div
          class="tabitem"
          :class="tabindex === 2 ? 'tabacbg' : ''"
          @click="tabChange(2)">
          我参与的
        </div>
        <div class="typebox" v-show="tabindex === 2">
          <div class="selectbox">
            <div class="select-box point" @click="istype = !istype">
              <div class="input-value flex-s">
                {{ parseSelectList.find((item) => item.value == value)?.label }}
              </div>
              <div class="row">
                <img
                  src="../../assets/images/newuser/mb-rechange-pullicon.png"
                  alt="" />
              </div>
              <div v-show="istype" class="float-box">
                <div
                  @click="changeSelect(item)"
                  class="float-box-item flex-s"
                  v-for="(item, index) in parseSelectList"
                  :key="index">
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <!-- 官方 -->
        <div v-show="this.tabindex == 0">
          <rollItem
            v-for="(item, index) in roomlist0"
            :key="index"
            :item="item"
            type="1"
            @itemClick="toRoom"></rollItem>
          <div class="nulldata" v-show="roomstate0">
            <div class="imgbox">
              <img src="@/assets/images/newuser/mb-table-nulldata.png" alt="" />
              <div class="nulltext">暂无内容</div>
            </div>
          </div>
        </div>
        <!-- 主播 -->
        <div v-show="this.tabindex == 1">
          <rollItem
            v-for="(item, index) in roomlist1"
            :key="index"
            :item="item"
            @itemClick="toRoom">
          </rollItem>
          <div class="nulldata" v-show="roomstate1">
            <div class="imgbox">
              <img src="@/assets/images/newuser/mb-table-nulldata.png" alt="" />
              <div class="nulltext">暂无内容</div>
            </div>
          </div>
        </div>
        <div v-if="myjoin">
          <rollItem
            class="item-r"
            v-for="item in myroom"
            :key="item?.id"
            :item="item"
            type="1"
            @itemClick="toRoom"></rollItem>
          <div class="nulldata" v-show="this.myroom.length == 0">
            <div class="imgbox">
              <img src="@/assets/images/newuser/mb-table-nulldata.png" alt="" />
              <div class="nulltext">暂无内容</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tabbar :activeIndex="1" />
  </div>
</template>

<script>
import { Rooms, UserRooms } from "@/network/api.js";
import { tabList } from "./index.js";
// import leftFloat from "@/components/left-float/index.vue";
import rollItem from "./components/rollnewitem.vue";
import pagination from "@/components/pagination/index.vue";
import { mapState } from "vuex";
export default {
  name: "Roll",
  data() {
    return {
      RoomList: [], //房间列表
      roomlist0: [], //官方房间
      roomlist1: [], //主播房间
      myroom: [], //
      Status: "", //房间状态
      current: 0,
      tabList,
      searchroll: "", //搜索roll房
      //分页
      per_page: 0,
      PageNum: 0,
      tabindex: 0,
      myjoin: false,
      roomstate1: false,
      roomstate0: false,
      userrolltype: 1,
      selectList: [
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已完成",
        },
      ],
      value: 1,
      istype: false,
    };
  },
  components: {
    // leftFloat,
    rollItem,
    pagination,
  },
  computed: {
    ...mapState(["user"]),
    parseSelectList() {
      const array = this.selectList.map((item) => {
        return {
          label: item?.label || item,
          value: item?.value || item,
        };
      });
      return array;
    },
  },
  created() {
    this.GetRooms();
  },
  methods: {
    changeSelect(v) {
      this.value = v.value;
      this.userrolltype = v.value;
      this.GetUserRooms();
    },
    // tab切换
    tabChange(i) {
      this.tabindex = i;
      this.ChangeRolls(i);
    },
    currentChange(i) {
      this.current = i;
      this.ChangeRolls(i);
    },
    //获取房间列表(初始)
    GetRooms() {
      Rooms().then((res) => {
        // this.per_page = res.data.data.per_page;
        // this.PageNum = res.data.data.total;
        this.RoomList = res.data.data.data;
        this.roomlist0 = this.RoomList.filter((v) => {
          if (v.type == 0) {
            return v;
          }
        });
        this.roomlist1 = this.RoomList.filter((v) => {
          if (v.type == 1) {
            return v;
          }
        });
        if (this.roomlist0.length == 0 && this.roomlist1.length == 0) {
          this.roomstate0 = true;
          this.roomstate1 = true;
        } else {
          this.roomstate0 = false;
          this.roomstate1 = false;
        }
      });
    },

    //参加房间记录(我参与的)
    GetUserRooms() {
      let params = {
        page: 1,
        type: this.userrolltype,
      };
      UserRooms(params).then((res) => {
        this.myroom = res.data.data.data;
      });
    },
    handleCurrentChange(val) {
      this.GetRooms(val);
      this.GetUserRooms(val);
    },
    //改变房间列表
    ChangeRolls(v) {
      switch (v) {
        case 0:
          this.GetRooms();
          this.myjoin = false;
          this.userrolltype = 1;
          break;
        case 1:
          this.GetRooms();
          this.myjoin = false;
          this.userrolltype = 1;
          break;
        case 2:
          if (!this.user.id) {
            this.$message({
              message: "认证失败，请重新登录！",
              type: "error",
            });
          } else {
            this.myjoin = true;
            this.GetUserRooms();
          }
          break;
      }
    },

    //路由跳转房间详情
    toRoom(id) {
      this.$router.push({
        path: "/room",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.typebox {
  position: absolute;
  right: 0.2rem;
}
.selectbox {
  .select-box {
    width: 0.73rem;
    height: 0.28rem;
    background: #08080f;
    border: 0.01rem solid #2f2f5e;
    position: relative;
    .input-value {
      width: 100%;
      height: 100%;
      line-height: 0.28rem;
      padding-left: 0.02rem;
      box-sizing: border-box;
      font-size: 0.1rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #c5bbed;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 0.1rem;
    }
    .row {
      position: absolute;
      top: 0.05rem;
      right: 0.2rem;
      z-index: 1;
      img {
        width: 0.17rem;
        height: 0.16rem;
      }
    }

    .float-box {
      position: absolute;
      left: -0.01rem;
      background: #08080f;
      z-index: 99;
      border: 0.01rem solid #2f2f5e;
      border-top: 0;
      .float-box-item {
        width: 0.71rem;
        height: 0.22rem;
        line-height: 0.22rem;
        padding: 0 0.02rem;
        box-sizing: border-box;
        font-size: 0.1rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #c5bbed;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 0.1rem;
      }
      .float-box-item:hover {
        background: #2f2f5e;
        color: #c5bbed;
      }
    }
  }
}

.tabbox {
  width: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.15rem 0.2rem;
  .tabitem {
    width: 0.74rem;
    height: 0.28rem;
    background: #020715;
    border: 0.01rem solid #3838ab;
    font-size: 0.1rem;
    line-height: 0.28rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.04rem;
  }

  .tabacbg {
    background: linear-gradient(135deg, #383876 100%, #222248 100%);
  }
}

.Roll_box {
  width: 100%;
  overflow: hidden;

  .Roll_Nav {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      height: 30px;
      padding: 0 26px;
      background: #191c34;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #fff;
      margin: 0 5px;

      &.active {
        background-image: linear-gradient(90deg, #ecb105, #ec7205);
      }
    }
  }

  .Roll_List {
    width: 1400px;
    // border: 1px solid red;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .center {
    width: 3.35rem;
    margin: 0 auto;
    .nulldata {
      width: 100%;
      min-height: 2rem;
      max-height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .imgbox {
        width: 0.8rem;
        height: 0.8rem;
        img {
          width: 100%;
        }
        .nulltext {
          text-align: center;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #c5bbed;
        }
      }
    }
  }
}
</style>
